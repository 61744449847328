import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, Transition as _Transition, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_RouterView, null, {
      default: _withCtx(({ Component, route }) => [
        _createVNode(_Transition, {
          "enter-active-class": "animated animate__fadeIn",
          "leave-active-class": "animated animate__fadeOut",
          mode: "out-in",
          duration: 140
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layouts[route.meta?.layout || 'empty']), {
              key: route.fullPath
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2
            }, 1024))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ]))
}