import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 1,
  style: {"height":"50px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTopBar = _resolveComponent("AppTopBar")!
  const _component_AppNavigation = _resolveComponent("AppNavigation")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { class: "defaultLayoutContainer" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, {
        class: "bg-white",
        elevated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppTopBar),
          (_ctx.$route.params.project !== 'master')
            ? (_openBlock(), _createBlock(_component_AppNavigation, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_1))
        ]),
        _: 1
      }),
      _createVNode(_component_q_page_container, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}