import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import Cookies from 'js-cookie'

const cookie = Cookies.get(process.env.VUE_APP_TRAIND_COOKIE)
if (cookie) {
  const AUTH_TOKEN = process.env.VUE_APP_APOLLO_TOKEN

  localStorage.setItem(AUTH_TOKEN, cookie)
}

createApp(App).use(Quasar, quasarUserOptions).use(router).mount('#app')
