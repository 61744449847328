
import AppAccountDropdown from '@/components/AppAccountDropdown.vue'
import AppLogo from '@/components/AppLogo.vue'
import { useProfile } from '@/services/profile'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppTopBar',

  components: {
    AppLogo,
    AppAccountDropdown,
  },

  setup() {
    const { user } = useProfile()

    return { user }
  },
})
