import { client } from '@/services/api-client'
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { gql } from 'graphql-request'
import { removeTimezoneFromDateString } from '@/common/removeTimezoneFromDateString'

export const getVehicleWithdrawal = (): Promise<VehicleWithdrawal[]> =>
  client
    .request<{ vehicle_withdrawal: VehicleWithdrawal[] }>(
      gql`
        query getVehicleWithdrawal {
          vehicle_withdrawal {
            uuid
            event_id
            vehicle_uuid
            wo
            # vehicle_withdrawal_cause_uuid
            vehicle_withdrawal_reason_uuid
            vehicle_withdrawal_responsible_uuid
            vehicle_withdrawal_depot_uuid
            vehicle {
              uuid
              name
              vehicle_type {
                uuid
                name
              }
            }
            vehicle_compositions {
              uuid
              vehicles {
                name
              }
            }
            withdrawn
            prognosis
            ready
            comment
            logs {
              created
              method
              user
              change
            }
          }
        }
      `
    )
    .then((data) =>
      data.vehicle_withdrawal.map((x) => ({
        ...x,
        prognosis: x.prognosis
          ? removeTimezoneFromDateString(x.prognosis)
          : null,
        wo: x.wo || '',
        withdrawn: x.withdrawn ? removeTimezoneFromDateString(x.withdrawn) : '',
        ready: x.ready ? removeTimezoneFromDateString(x.ready) : '',
        // logs: x.logs.map((log) => ({
        //   ...log,
        //   created: removeTimezoneFromDateString(log.created),
        // })),
      }))
    )
