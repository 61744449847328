import { RouteRecordRaw } from 'vue-router'

export type AppRouteNames = 'vehicle-withdrawal' | 'sign-in' | 'history'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/sign-in',
    name: 'sign-in',
    meta: {
      header: 'Logga in',
    },
    component: () =>
      import(/* webpackChunkName: "sign-in" */ '@/views/SignIn.vue'),
  },
  {
    path: '/',
    alias: '/vehicle-withdrawal',
    name: 'vehicle-withdrawal',
    meta: {
      header: 'Avställningar',
      layout: 'default',
      permission: 'vehicleWithdrawal.view',
    },
    component: () =>
      import(
        /* webpackChunkName: "vehicle-withdrawal" */ '@/views/VehicleWithdrawal.vue'
      ),
  },
  {
    path: '/',
    alias: '/history',
    name: 'history',
    meta: {
      header: 'Historik',
      layout: 'default',
      permission: 'vehicleWithdrawal.history',
    },
    component: () =>
      import(/* webpackChunkName: "history" */ '@/views/History.vue'),
  },
  {
    path: '/',
    alias: '/vehicle-report',
    name: 'vehicle-report',
    meta: {
      header: 'Fordonsrapport',
    },
    component: () =>
      import(
        /* webpackChunkName: "vehicleReport" */ '@/views/VehicleReport.vue'
      ),
  },
  {
    path: '/',
    alias: '/vehicle-report-print',
    name: 'vehicle-report-print',
    meta: {
      header: 'Fordonsrapport',
    },
    component: () =>
      import(
        /* webpackChunkName: "vehicleReportPrint" */ '@/views/VehicleReportPrint.vue'
      ),
  },
  {
    path: '/',
    alias: '/vehicle-report-pdf',
    name: 'vehicle-report-pdf',
    meta: {
      header: 'Fordonsrapport',
    },
    component: () =>
      import(
        /* webpackChunkName: "vehicleReportPrint" */ '@/views/VehicleReportPrint.vue'
      ),
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    meta: {
      header: 'Ej behörighet',
    },
    component: () =>
      import(/* webpackChunkName: "forbidden" */ '@/views/Forbidden.vue'),
  },
]
