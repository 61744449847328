import { GraphQLClient } from 'graphql-request'
import axios from 'axios'

export const getToken = (): string => {
  const { pathname, search } = window.location
  let token = localStorage.getItem(process.env.VUE_APP_APOLLO_TOKEN) || ''
  if (
    pathname.includes('vehicle-report-pdf') &&
    search.includes('access_token')
  ) {
    token = search.replace('?access_token=', '')
  }

  return token
}

export const userInfoClient = new GraphQLClient(
  'https://gateway.traind.se/query'
)

export const client = new GraphQLClient(
  'https://traind-calendar-v3-graphql.reges-services.se'
)

export const alphaClient = axios.create({
  baseURL: 'https://alpha.traind.se',
})

alphaClient.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${getToken()}`
  }

  return config
})
