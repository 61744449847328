
import { useVehicleWithdrawal } from '@/compositions/useVehicleWithdrawal'
import { useProfile } from '@/services/profile'
import { defineComponent, ref, computed, ComputedRef } from 'vue'
import { useRoute } from 'vue-router'

interface NavigationTab {
  label: string
  to?: string
  badge?: number
}

export default defineComponent({
  name: 'AppNavigation',

  setup() {
    const route = useRoute()
    const { can } = useProfile()
    const { showVehicleStatusMenu } = useVehicleWithdrawal()
    const activeNavigationTab = ref(route.name)

    const navigationTabs: ComputedRef<NavigationTab[]> = computed(() => {
      return [
        {
          key: 'vehicle-withdrawal',
          label: 'Avställda fordon',
          to: `/vehicle-withdrawal`,
          visible: true,
          permission: 'vehicleWithdrawal.view',
        },
        {
          key: 'history',
          label: 'Historik',
          to: `/history`,
          visible: true,
          permission: 'vehicleWithdrawal.history',
        },
      ].filter((x) => can(x.permission))
    })

    return {
      activeNavigationTab,
      navigationTabs,
      showVehicleStatusMenu,
    }
  },
})
