
import { defineComponent } from 'vue'
import LayoutDefafult from './layouts/LayoutDefault.vue'
import LayoutEmpty from './layouts/LayoutEmpty.vue'

const layouts = {
  default: LayoutDefafult,
  empty: LayoutEmpty,
}

export default defineComponent({
  name: 'App',

  setup() {
    return {
      layouts,
    }
  },
})
