import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'
import { useProfile } from '@/services/profile'

const { isSignedIn, signOut, getUserInfo, can, getUserPermissions } =
  useProfile()

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to) => {
  await getUserInfo()
  const signedIn = isSignedIn.value

  if (!signedIn && to.name !== 'sign-in') {
    return signOut()
  }

  if (signedIn && to.name === 'sign-in') return '/'

  if (signedIn) {
    await getUserPermissions()
  }

  if (signedIn && to?.meta?.permission && !can(to.meta.permission))
    return '/forbidden'

  document.title = 'Transdev' + ' | ' + to?.meta?.header || ''
})

export default router
