import { gql } from 'graphql-request'
import { ref, Ref } from 'vue'
import { client, userInfoClient, alphaClient, getToken } from './api-client'
import Cookies from 'js-cookie'

interface Rule {
  actions: string | string[]
  subject: string | string[]
}

interface User {
  Rules: Rule[]
  Email: string | null
  Account: string | null
  IsDev: boolean
}

interface UseProfile {
  user: Ref<User>
  isSignedIn: Ref<boolean>
  permissions: Ref<Permissions>
  getUserInfo: () => Promise<void>
  signOut: () => void
  getUserPermissions: () => void
  can: (permission: unknown) => boolean
}

interface Permissions {
  [permission: string]: boolean
}

const query = gql`
  query getUserInfo {
    userInfo {
      Rules
      Email
      Account
      IsDev
    }
  }
`

const createUserModel = () => ({
  Rules: [],
  Email: null,
  Account: null,
  IsDev: false,
})

const user = ref<User>(createUserModel())
const permissions = ref<Permissions>({})

function setHeaders() {
  userInfoClient.setHeader('Authorization', `Bearer ${getToken()}`)
  client.setHeader('authorization', `Bearer ${getToken()}`)
}

const isSignedIn = ref(false)

export const useProfile = (): UseProfile => {
  function signOut() {
    window.localStorage.removeItem('transdev:token')

    isSignedIn.value = false
    user.value = createUserModel()
    // window.location.href = '/sign-in'
    localStorage.removeItem(process.env.VUE_APP_APOLLO_TOKEN)
    Cookies.remove(process.env.VUE_APP_TRAIND_COOKIE)
    Cookies.remove(process.env.VUE_APP_TRAIND_COOKIE, {
      domain: 'traind.se',
      path: '/',
    })
    window.location.href = 'https://www.traind.se/login/'
  }

  function getUserInfo() {
    setHeaders()
    return userInfoClient
      .request<{ userInfo: User }>(query)
      .then((data) => {
        user.value = data.userInfo
        isSignedIn.value = true
      })
      .catch(() => {
        isSignedIn.value = false
      })
  }

  async function getUserPermissions() {
    const userInfoData = (await alphaClient
      .get('/iam/userPermissions')
      .then((res) => res.data)
      .catch(() => {
        return { permissions: [] }
      })) as [string]
    permissions.value = userInfoData.reduce<{ [permission: string]: boolean }>(
      (acc, permission) => {
        acc[permission] = true

        return acc
      },
      {}
    )
  }

  function can(permission: unknown) {
    if (typeof permission !== 'string') return true
    return !!permissions.value[permission]
  }

  return {
    user,
    isSignedIn,
    getUserInfo,
    signOut,
    getUserPermissions,
    can,
    permissions,
  }
}
