
import { useProfile } from '@/services/profile'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppAccountDropdown',

  setup() {
    const { user, signOut } = useProfile()

    return {
      signOut,
      user,
    }
  },
})
