import { getVehicleWithdrawal } from '@/api/vehicle-withdrawal/getVehicleWithdrawal'
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { Ref, ref } from 'vue'

interface UseVehicleWithdrawal {
  fetchVehicleWithdrawals: () => Promise<VehicleWithdrawal[]>
  data: Ref<VehicleWithdrawal[]>
  loading: Ref<{ get: boolean }>
  showVehicleStatusMenu: Ref<boolean>
}

const data = ref<VehicleWithdrawal[]>([])
const loading = ref({
  get: false,
})
const showVehicleStatusMenu = ref(false)

export const useVehicleWithdrawal = (): UseVehicleWithdrawal => {
  const fetchVehicleWithdrawals = async () => {
    loading.value.get = true
    data.value = await getVehicleWithdrawal()
    loading.value.get = false
    return data.value
  }

  return {
    showVehicleStatusMenu,
    loading,
    data,
    fetchVehicleWithdrawals,
  }
}
