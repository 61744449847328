
import { defineComponent } from 'vue'
import AppTopBar from '@/components/AppTopBar.vue'
import AppNavigation from '@/components/AppNavigation.vue'

export default defineComponent({
  name: 'LayoutDefault',

  components: {
    AppTopBar,
    AppNavigation,
  },
})
